export enum ContactOption {
  Classroom = 'classroom',
  TailorMade = 'tailor-made',
  Course = 'course',
  Signup = 'signup',
  Registration = 'registration',
  NoSubject = 'no-subject',
  SpecificWebinar = 'specific-webinar',
  TrainingPlanCoaching = 'training-plan-coaching',
}
