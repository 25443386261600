
import { TranslatedProperty } from '@/models/shared/translated-property.class';
import StaffMember from '@/models/staff-members/staff-member.class';
import { translateApi } from '@/utils/translate-api';
import { Prop, Vue, Component } from 'vue-property-decorator';

@Component({
  filters: { translateApi },
})
export default class MezureContact extends Vue {
  @Prop({ type: Object, required: true })
  contactMember!: StaffMember;

  @Prop({ type: Boolean, required: false })
  isContactPage = false;

  get contact() {
    return { ...this.contactMember };
  }

  get translation() {
    if (this.contact) {
      const res = translateApi(this.contact.description as TranslatedProperty, this.$i18n.locale);
      return res;
    }
    return false;
  }

  goToContactPage() {
    this.$router.push({ name: 'contact' });
  }
}
