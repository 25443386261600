
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { StaticContentType } from '../enums/static-content-type.enum';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { Coaching as CoachingModel } from '../models/static-contents/coaching.interface';
import { translateApi } from '../utils/translate-api';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import CoachingStep from '../components/CoachingStep.vue';
import MezureContact from '../components/MezureContact.vue';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import { RawLocation } from 'vue-router';
import { ContactOption } from '../enums/contact-option.enum';
import StaffMember from '@/models/staff-members/staff-member.class';

const staticContentsModule = namespace('staticContents');
const staffModule = namespace('staffMembers');

@Component({
  components: {
    CoachingStep,
    MezureContact,
  },
  filters: {
    translateApi,
  },
})
export default class Coaching extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: (type: StaticContentType) => CoachingModel;

  @staffModule.Action('fetchUnpaginated')
  fetchStaffMembers!: () => StaffMember[];

  @staffModule.Getter('all')
  staffMembers!: StaffMember[];

  coaching: CoachingModel | null = null;
  coachingContact: StaffMember | null = null;

  get headerHtml() {
    if (!this.coaching) {
      return '';
    }

    return `<h2 class="text-white mb-5">${translateApi(
      this.coaching.title.value,
      this.$i18n.locale,
    )}</h2>
        <p class="text-white">${translateApi(this.coaching.subtitle.value, this.$i18n.locale)}</p>
        `;
  }

  goToContactPage() {
    const route: RawLocation = {
      name: 'contact',
      params: {
        locale: this.localeCode,
        subject: ContactOption.TrainingPlanCoaching,
      },
    };

    if (this.localeCode === 'nl') {
      delete route.params?.locale;
    }

    this.$router.push(route);
  }

  async created() {
    if (!this.getStaticContentByType(StaticContentType.Coaching)) {
      await this.fetchStaticContent(StaticContentType.Coaching);
    }
    this.coaching = this.getStaticContentByType(StaticContentType.Coaching);
    await this.fetchStaffMembers();
    this.coachingContact =
      this.staffMembers.find(memb => memb.staffVisiblePages.includes('Trainingpage')) || null;
  }
}
